
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Attendance } from "./Attendance";



/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
    return (
        <AuthenticatedTemplate><Attendance></Attendance></AuthenticatedTemplate>
    );
}
