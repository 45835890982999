import { AuthenticatedTemplate } from "@azure/msal-react";
import { NavigationBar } from "./NavigationBar";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { protectedResources } from "../authConfig";
import "../styles/PageLayout.css";

export const PageLayout = (props) => {

    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes,
    });

    const [adminData, setAdminData] = useState(null);

    useEffect(() => {
        if (!adminData) {
            execute("GET", protectedResources.api.administrator)
                .then((response) => {
                    if (response?.data) {
                        console.log("Response administrator: ", response.data);
                        setAdminData(response.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching administrator:", error);
                });
        }
    }, [execute]);

    const currentYear = new Date().getFullYear();

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <NavigationBar adminData={adminData}/>
            <br />
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
                <footer className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright © Desarrollo en Cuatro Consultores {currentYear} V2.0 [28-03-2025]</span>
                        </div>
                    </div>
                </footer>
            </AuthenticatedTemplate>
        </>
    );
};
