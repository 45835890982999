import React, { useState } from "react";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { MyAbsenceForm } from "./MyAbsenceForm";
import Card from '@mui/material/Card';
import { MyAbsencesGrid } from "./MyAbsencesGrid";
import { usePosition } from '../hooks/usePosition';
import LoadingSpinner from "./LoadingSpinner";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const customId = "custom-id-yes";

export const MyAbsencesView = (props) => {

    const { latitude, longitude } = usePosition();
    const [attendance, setAttendance] = useState(null);
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false); 
    const { error, isLoading, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const handleSelectJustificar = (attendance) => {
        if(attendance){
            setAttendance(attendance);
            setFlag(true);
        }else{
            setFlag(false);
        }  
    };

    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 512) {
            const slice = byteCharacters.slice(i, i + 512);
            const byteNumbers = new Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteNumbers[j] = slice.charCodeAt(j);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        return new Blob(byteArrays, { type: mimeType });
    };

    const handleRequestJustificar = async (excuse) => {
        excuse.latitude = latitude;
        excuse.longitude = longitude;

        const formData = new FormData();
        formData.append("attendanceId", excuse.attendanceId);
        formData.append("comment", excuse.comment);
        if (excuse?.excuseId !== undefined && excuse?.excuseId !== null) {
            formData.append("excuseId", excuse.excuseId);
        }
        formData.append("latitude", excuse.latitude ? excuse.latitude.toString() : "0");
        formData.append("longitude", excuse.longitude ? excuse.longitude.toString() : "0");
        if (excuse?.file?.base64) {
            const blob = base64ToBlob(excuse.file.base64, excuse.file.type);
            const fileFromBlob = new File([blob], excuse.file.name, { type: excuse.file.type });
            formData.append("file", fileFromBlob);
        } 

        setLoading(true);

        for (let pair of formData.entries()) {
            if (pair[1] instanceof File) {
                console.log(pair[0] + ": [Archivo] " + pair[1].name);
            } else {
                console.log(pair[0] + ": " + pair[1]);
            }
        }

        const timeout = setTimeout(() => {
            alert("La solicitud está tardando demasiado...");
        }, 10000); // 10 segundos

        execute("POST", protectedResources.api.excuses, excuse).then((response) => {
            clearTimeout(timeout);
            setLoading(false);
            if (response?.status == 200) {
                setAttendance(null);
                setFlag(false);
            }
        }).catch((error) => {
            clearTimeout(timeout);
            setLoading(false);
            console.log(error?.message || JSON.stringify(error));
        });

    }

    if (error) {
        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    const renderForm = (
        <Card variant="outlined" style={{ width: '100%', overflowX: 'auto' }}>
            <h2 className="tituloH2 mt-3">Mis inasistencias</h2>
            {!flag && <MyAbsencesGrid handleSelectJustificar={handleSelectJustificar}/>}
            {flag && <MyAbsenceForm handleSelectJustificar={handleSelectJustificar} attendance={attendance} handleRequestJustificar={handleRequestJustificar} />}
        </Card>
    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}
