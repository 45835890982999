import React, { useState, useEffect } from "react";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { AttendanceForm } from "./AttendanceForm";
import { TimeView } from "./TimeView";

import Card from '@mui/material/Card';

import { WelcomeMessagge } from "./WelcomeMessagge";
import { FarewellMessagge } from "./FarewellMessagge";
import { usePosition } from '../hooks/usePosition';
import LoadingSpinner from "../components/LoadingSpinner";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const customId = "custom-id-yes";

export const AttendanceView = (props) => {
    const { latitude, longitude } = usePosition();
    const [checkIn, setCheckIn] = useState(props.data.checkIn);
    const [checkOut, setCheckOut] = useState(props.data.checkOut);
    const [attendanceId, setAttendanceId] = useState(props.data.attendanceId);
    const [flag, setFlag] = useState(false);
    const { error, isLoading, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const handleFlag = () => {
        setFlag(false);
    };


    const handleCheckIn = (attendance) => {
        attendance.latitude = latitude;
        attendance.longitude = longitude;
        execute("POST", protectedResources.api.attendance, attendance).then((response) => {
            if (response && response.status == 200) {
                setCheckIn(response.data.checkIn);
                setAttendanceId(response.data.attendanceId);
                setFlag(true);
            }
        }).catch((error) => {
            console.log(error);

        });



    }

    const handleCheckOut = (attendance) => {
        attendance.latitude = latitude;
        attendance.longitude = longitude;
        execute("PUT", protectedResources.api.attendance + `/${ attendanceId }`, attendance).then((response) => {
            if (response && response.status == 200) {
                setCheckOut(response.data.checkOut)
            }
        });


    }

    if (error) {

        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    const renderForm = (

        <Card variant="outlined">
            <h2 className="tituloH2 mt-3">Registra tu asistencia</h2>
            <TimeView currentTime={props.data.time} checkIn={checkIn} checkOut={checkOut} />

            {flag && !!checkIn && <WelcomeMessagge handleFlag={handleFlag}></WelcomeMessagge>}
            {!!checkOut && <FarewellMessagge></FarewellMessagge>}
            {!flag && (!!checkIn == false || !!checkOut == false) && <AttendanceForm currentTime={props.data.time} checkIn={checkIn} attendanceId={attendanceId} handleCheckIn={handleCheckIn} handleCheckOut={handleCheckOut} />}
        </Card>

    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}
