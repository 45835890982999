import React, { useState, useEffect } from "react";
import { Button, Form,  } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateTime } from "luxon";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import Comentarios from '../components/Comentarios.jsx';

export const MyAbsenceForm = (props) => {
    const { execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const handleSelectJustificar = () => {
        props.handleSelectJustificar();
    }

    const [comments, setComments] = useState(null);
    const [attendance, setAttendance] = useState(props.attendance);
    const [comment, setComment] = useState('');
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        if (!comments && attendance.excuse?.excuseId) {
            execute("GET", protectedResources.api.comments+'/'+attendance.excuse?.excuseId).then((response) => {
                if (response && response.data) {
                    setComments(response.data);
                }
            });
        }
    }, [execute, comments]);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }

        const excuse = {
            attendanceId: attendance.attendanceId,
            comment: comment,
            excuseId: attendance.excuse?.excuseId,
            file: formData
        };
        props.handleRequestJustificar(excuse);
    }

    const removeAccents = (str) => {
        return str.slice(0, -1);
    };
    
    const handleCommentsChange = (e) => {
        if (/^[A-Za-z0-9\s]+$/g.test(e.target.value)) {
            setComment(e.target.value);
        } else {
            e.target.value = removeAccents(e.target.value);
            setComment(e.target.value);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
            const maxSize = 10 * 1024 * 1024; // 10MB

            if (!allowedTypes.includes(file.type)) {
                alert("Solo se permiten archivos PNG, JPG y PDF.");
                return;
            }

            if (file.size > maxSize) {
                alert("El archivo es demasiado grande (máx. 10MB).");
                return;
            }

            console.log("Archivo seleccionado: ", file.name.normalize("NFC"));
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result.split(',')[1];

                const fileData = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    base64: base64Data
                };

                setFormData(fileData);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6} >
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <h6 className="tituloH6">Justifique su falta</h6>
                        <Row className="mb-3 mt-1">
                            <span className="css-time-title-typography">fecha</span>
                            <Container className="css-time-content">
                                <span className="css-time-typography">{DateTime.fromISO(attendance.dateAttendance, { setZone: 'utc' }).toFormat('yyyy-MM-dd')}</span>
                            </Container>
                        </Row>
                        <Form.Group className="mb-3" controlId="formGridAddress3">
                            <FloatingLabel controlId="floatingTextarea2" label="Justificaci&oacute;n">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    onChange={handleCommentsChange}
                                    style={{ height: '100px' } }
                                    required
                                />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress3">
                            <div className='form-group'>
                                <label htmlFor="clientName">Cargar evidencia en PNG, JPEG o PDF </label>
                                <input 
                                    type="file" 
                                    name="file" 
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={handleFileUpload} 
                                />
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Button variant="secondary" size="lg"  className="mb-3 me-3" onClick={(event) => handleSelectJustificar()} >Cancelar</Button>
                            <Button variant="bs-yellow" type="submit" size="lg" style={{ background: '#ffda00' }} className="mb-3 me-3">Solicitar justificaci&oacute;n</Button>
                        </Form.Group>
                    </Form>
                    {comments && <Comentarios messages={comments}></Comentarios>}
                </Col>
            </Row>
        </Container >
    );
}
