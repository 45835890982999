import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Card from '@mui/material/Card';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { ReportsGrid } from "./ReportsGrid";
import LoadingSpinner from "./LoadingSpinner";
import ShowToast from '../components/ShowToast.jsx';

const customId = "custom-id-yes";

export const ReportsView = ({ administrator }) => {
    const { error, isLoading, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    if (error) {
        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const crearReporteDefinitivo = () => {
        console.log("crearReporteDefinitivo");
        execute("POST", protectedResources.api.reports).then((response) => {
            console.log("Response reports: ", response);
            if (response && response.status == 200) {
                // setFlag(true);
                ShowToast("success", "Reporte generado exitosamente");
            } else {
                ShowToast("error", response?.message);
            }
        }).catch((error) => {
            console.log(error);
            ShowToast("error", "Error al generar el reporte");
        });
    };

    const renderForm = (
        <Card variant="outlined">
            {administrator?.isAdmin && (
                <div className="d-flex justify-content-end pe-3 pt-3">
                    <Button variant="primary" size="lg" onClick={crearReporteDefinitivo}>
                        Reporte definitivo
                    </Button>
                </div>
            )}
            <h2 className="tituloH2 mt-3">Registro de reportes</h2>
            <ReportsGrid />
        </Card>
    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}
