import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from "../authConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useEffect, useState } from 'react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';

import { DateTime } from "luxon";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${ tableCellClasses.head }`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${ tableCellClasses.body }`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const MyEmployeesGrid = (props) => {
    const STORAGE_KEY = "MyEmployeesGrid_filters";

    const handleSelectJustificar = (attendance) => {
        props.handleSelectJustificar(attendance);
    }

    const authRequest = {
        ...loginRequest,
    };

    const { isLoading, error, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const loadStoredFilters = () => {
        const storedFilters = localStorage.getItem(STORAGE_KEY);
        return storedFilters ? JSON.parse(storedFilters) : { fecha: "", nombre: "", estatus: "" };
    };

    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(loadStoredFilters);

    const handleTextStatusExcuse = (excuse) => {
        var status ='';
         if(excuse){
         switch (excuse.status) {
 
             case 0:
                 status= 'Pendiente'
                 break;
             case 1:
                 status= 'Justificada'
                 break;
             case 2:
                 status= 'Rechazada'
                 break;
             default:
                 break;
         }
        }else{
         status= 'No Solicitada'
        }
        return status;
     }

     const handleStatusExcuse = (excuse) => {
         if(excuse && excuse.status == 1){
            return false;
        }else{
            return true;
        }
     }

    useEffect(() => {
        execute("GET", protectedResources.api.employeesAbsences).then((response) => {
            if (response?.data) {
                setData(response.data);
                // var information = JSON.stringify(response.data);
                // console.log(information);
            }
        });
    }, [execute]);

    const filteredData = data.filter(row => {
        const fechaFormatted = DateTime.fromISO(row.dateAttendance, { setZone: 'utc' }).toFormat('yyyy-MM-dd');
        const nombre = row.employee.name.toLowerCase();
        const estatusText = handleTextStatusExcuse(row.excuse).toLowerCase();
        return (
            fechaFormatted.includes(filters.fecha) &&
            nombre.includes(filters.nombre.toLowerCase()) &&
            estatusText.includes(filters.estatus.toLowerCase())
        );
    });

    const handleFilterChange = (field, value) => {
        setFilters(prev => {
            const newFilters = { ...prev, [field]: value };
            localStorage.setItem(STORAGE_KEY, JSON.stringify(newFilters));
            return newFilters;
        });
    };

    const columns = [
        {
            // name: "Fecha",
            selector: row => DateTime.fromISO(row.dateAttendance, { setZone: 'utc' }).toFormat('yyyy-MM-dd'),
            sortable: false,
            grow: 1,
            cell: row => DateTime.fromISO(row.dateAttendance, { setZone: 'utc' }).toFormat('yyyy-MM-dd'),
            filterComponent: (
                <input
                    type="text"
                    placeholder="Fecha"
                    className="form-control"
                    value={filters.fecha}
                    onChange={(e) => handleFilterChange("fecha", e.target.value)}
                />
            )
        },
        {
            // name: "Nombre",
            selector: row => row.employee.name,
            sortable: false,
            grow: 1,
            cell: row => row.employee.name,
            filterComponent: (
                <input
                    type="text"
                    placeholder="Nombre"
                    className="form-control"
                    value={filters.nombre}
                    onChange={(e) => handleFilterChange("nombre", e.target.value)}
                />
            )
        },
        {
            // name: "Estatus",
            selector: row => handleTextStatusExcuse(row.excuse),
            sortable: false,
            grow: 1,
            cell: row => handleTextStatusExcuse(row.excuse),
            filterComponent: (
                <input
                    type="text"
                    placeholder="Estatus"
                    className="form-control"
                    value={filters.estatus}
                    onChange={(e) => handleFilterChange("estatus", e.target.value)}
                />
            )
        },
        {
            name: "Acción",
            cell: row => handleStatusExcuse(row.excuse) ? (
                <Button variant="primary" onClick={() => handleSelectJustificar(row)}>Justificar</Button>
            ) : null,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '200px'
        },
    ];

    const renderForm = (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            <div className="container">
                <DataTable
                    columns={columns.map(col => ({
                        ...col,
                        name: (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                {col.name}
                                {col.filterComponent && <div>{col.filterComponent}</div>}
                            </div>
                        )
                    }))}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    responsive
                    fixedHeader
                    noDataComponent="No hay datos disponibles"
                    persistTableHead
                />
            </div>
        </MsalAuthenticationTemplate>
    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}