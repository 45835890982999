import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Nav, Navbar, Dropdown, DropdownButton, Container } from 'react-bootstrap';

import { loginRequest } from '../authConfig';
import { AccountPicker } from './AccountPicker';

export const NavigationBar = ({ adminData }) => {
    const [showProfilePicker, setShowProfilePicker] = useState(false);
    const { instance } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLoginPopup = () => {
        /**
         * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page 
         * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
         * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations 
         */

        instance.loginPopup({
            ...loginRequest,
            redirectUri: '/redirect.html'
        }).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: instance.getActiveAccount(),
        });
    };

    const handleSwitchAccount = () => {
        setShowProfilePicker(!showProfilePicker);
    };

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <Navbar bg='black' variant="dark" expand="lg" className="navbarStyle">
                <Container>
                    <Navbar.Brand href="/">
                        AttenD4nce
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <AuthenticatedTemplate>
                                <Nav.Link className="navbarButton" href="/my-absences">
                                    Mis Faltas
                                </Nav.Link>
                                {adminData?.isManager && (
                                    <Nav.Link className="navbarButton" href="/employees-absences">
                                        Mis Pendientes
                                    </Nav.Link>
                                )}
                                {adminData?.isAdmin && (
                                    <Nav.Link className="navbarButton" href="/reports">
                                        Reportes
                                    </Nav.Link>
                                )}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate title="Sign In">
                                <Nav.Link className="navbarButton" onClick={handleLoginPopup}>
                                    Sign in using Popup
                                </Nav.Link>
                                <Nav.Link className="navbarButton" onClick={handleLoginRedirect}>
                                    Sign in using Redirect
                                </Nav.Link>
                            </UnauthenticatedTemplate>
                        </Nav>
                        <div className="ms-auto">
                            <AuthenticatedTemplate>
                                <DropdownButton
                                    variant="warning"
                                    drop="down"
                                    title={activeAccount ? activeAccount.name.split(' ')[0] : 'Unknown'}
                                >
                                    <Dropdown.Item as="button" onClick={handleSwitchAccount}>
                                        Switch account
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={handleLogoutPopup}>
                                        Sign out using Popup
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                                        Sign out using Redirect
                                    </Dropdown.Item>
                                </DropdownButton>
                            </AuthenticatedTemplate>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <AccountPicker show={showProfilePicker} handleSwitchAccount={handleSwitchAccount} />
        </>
    );
};
