import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from "../authConfig";

import React, { useEffect, useState } from 'react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import LoadingSpinner from "../components/LoadingSpinner";
import { ReportsView } from '../components/ReportsView';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const customId = "custom-id-yes";

export const Reports = () => {

    const { isLoading, error, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const [adminData, setAdminData] = useState(null);

    const authRequest = {
        ...loginRequest,
    };

    useEffect(() => {
        if (!adminData) {
            execute("GET", protectedResources.api.administrator)
                .then((response) => {
                    if (response?.data) {
                        console.log("Response administrator: ", response.data);
                        setAdminData(response.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching administrator:", error);
                });
        }
    }, [execute, adminData]);

    if (error) {
        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const renderForm = (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            <ReportsView administrator={adminData}/>
        </MsalAuthenticationTemplate>
    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
};
