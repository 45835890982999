import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { Attendance } from "./pages/Attendance";
import { MyAbsences } from "./pages/MyAbsences";
import { MyEmployees } from "./pages/MyEmployees";
import { Reports } from "./pages/Reports";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/App.css";

const Pages = () => {
    return (
        <Routes>
            <Route
                exact
                path="/attendance"
                element={
                        <Attendance />
                }
            />
            <Route
                exact
                path="/my-absences"
                element={
                        <MyAbsences />
                }
            />
            <Route
                exact
                path="/employees-absences"
                element={
                        <MyEmployees />
                }
            />
            <Route
                exact
                path="/reports"
                element={
                    <Reports />
                }
            />
            <Route path="/" element={<Home />} />
        </Routes>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* Same as */}
            <ToastContainer />
            <PageLayout>
                <Pages instance={instance} />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;
