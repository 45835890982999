import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from "../authConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import React, { useEffect, useState } from 'react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import { DateTime } from "luxon";

export const ReportsGrid = (props) => {
    const STORAGE_KEY = "ReportsGrid_filters";

    const authRequest = { ...loginRequest,};

    const { isLoading, error, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const loadStoredFilters = () => {
        const storedFilters = localStorage.getItem(STORAGE_KEY);
        return storedFilters ? JSON.parse(storedFilters) : { fecha: "", nombre: "", tipo: "" };
    };

    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(loadStoredFilters);

    useEffect(() => {
        execute("GET", protectedResources.api.reports).then((response) => {
            if (response?.data) {
                setData(response.data);
                // var information = JSON.stringify(response.data);
                // console.log(information);
            }
        });
        
    }, [execute]);

    const filteredData = data.filter(row => {
        const fechaFormatted = DateTime.fromISO(row.createdAt, { setZone: 'utc' }).toFormat('yyyy-MM-dd');
        const nombre = row.description ? row.description.toLowerCase() : "";
        const tipo = row.reportType ? row.reportType.toLowerCase() : "";
    
        return (
            (!filters.fecha || fechaFormatted.includes(filters.fecha)) &&
            (!filters.nombre || nombre.includes(filters.nombre.toLowerCase())) &&
            (!filters.tipo || tipo.includes(filters.tipo.toLowerCase()))
        );
    });    

    const handleFilterChange = (field, value) => {
        setFilters(prev => {
            const newFilters = { ...prev, [field]: value };
            localStorage.setItem(STORAGE_KEY, JSON.stringify(newFilters));
            return newFilters;
        });
    };

    const columns = [
        {
            // name: "Fecha",
            selector: row => DateTime.fromISO(row.createdAt, { setZone: 'utc' }).toFormat('yyyy-MM-dd'),
            sortable: false,
            grow: 1,
            width: "15%",
            cell: row => DateTime.fromISO(row.createdAt, { setZone: 'utc' }).toFormat('yyyy-MM-dd'),
            filterComponent: (
                <input
                    type="text"
                    placeholder="Fecha"
                    className="form-control"
                    value={filters.fecha}
                    onChange={(e) => handleFilterChange("fecha", e.target.value)}
                />
            )
        },
        {
            // name: "Nombre",
            selector: row => row.description,
            sortable: false,
            grow: 1,
            width: "40%",
            cell: row => row.description,
            filterComponent: (
                <input
                    type="text"
                    placeholder="Nombre"
                    className="form-control"
                    value={filters.nombre}
                    onChange={(e) => handleFilterChange("nombre", e.target.value)}
                />
            )
        },
        {
            // name: "Tipo",
            selector: row => row.reportType,
            sortable: false,
            grow: 1,
            width: "20%",
            cell: row => row.reportType,
            filterComponent: (
                <input
                    type="text"
                    placeholder="Tipo"
                    className="form-control"
                    value={filters.tipo}
                    onChange={(e) => handleFilterChange("tipo", e.target.value)}
                />
            )
        },
        {
            name: "Descargar Reporte",
            cell: row => (
                <a href={row.file?.signedUrl} target="_blank" rel="noopener noreferrer">
                <Button variant="primary">
                    {row.reportType === "general" ? "General" : "Específico"}
                </Button>
            </a>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '200px',
            width: "25%"
        },
    ];

    const renderForm = (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
            <div className="container">
                <DataTable
                    columns={columns.map(col => ({
                        ...col,
                        name: (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                {col.name}
                                {col.filterComponent && <div>{col.filterComponent}</div>}
                            </div>
                        )
                    }))}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    responsive
                    fixedHeader
                    noDataComponent="No hay datos disponibles"
                    persistTableHead
                />
            </div>
        </MsalAuthenticationTemplate>
    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}