import React from 'react';
import clsx from 'clsx';
import { DateTime } from "luxon";

const Comentarios = ({ messages = [], tittle }) => {
    return (
        <div className='card-body'>
            {tittle && <h2 className="tituloH2 mt-3">{tittle}</h2>}
            <div className='scroll-y me-n5 pe-5' style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {/* <div className='border rounded p-3' style={{ maxHeight: '300px', overflowY: 'auto' }}> */}
                {messages.map((message, index) => {
                    const state = message.type === 'in' ? 'info' : 'primary';
                    let updatedText = message.text;
                    if (message.file) {
                        updatedText += "</br><strong>Evidencia:</strong> ";
                        const fileLink = `<a href="${message.file.signedUrl}" target="_blank" rel="noopener noreferrer" class="text-primary"><strong>${message.file.fileName}</strong></a>`;
                        updatedText += ` ${fileLink}`;
                    }

                    return (
                        <div key={`message${index}`} className={clsx('d-flex flex-column', `align-items-${message.type === 'in' ? 'start' : 'end'}`, 'mb-3')}>

                            <div className="d-flex align-items-center gap-2">
                                <span className="fs-6 fw-bold">{message.name}</span>
                                <span className="text-muted fs-7">
                                    {DateTime.fromISO(message.createdAt).setZone('America/Mexico_City').toLocaleString(DateTime.DATETIME_SHORT)}
                                </span>
                            </div>

                            <div
                                className={clsx(
                                    'p-3 rounded',
                                    `bg-light-${state}`,
                                    'text-gray-900 fw-bold mw-lg-400px'
                                )}
                                dangerouslySetInnerHTML={{ __html: updatedText }}
                            ></div>

                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Comentarios;
