import React, { useState } from "react";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { MyEmployeesForm } from "./MyEmployeesForm";


import Card from '@mui/material/Card';

import { MyEmployeesGrid } from "./MyEmployeesGrid";

import { usePosition } from '../hooks/usePosition';
import LoadingSpinner from "./LoadingSpinner";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const customId = "custom-id-yes";

export const MyEmployeesView = (props) => {

    const { latitude, longitude } = usePosition();

    const [attendance, setAttendance] = useState(null);

    const { error, isLoading, execute } = useFetchWithMsal({
        scopes: protectedResources.api.scopes
    });

    const handleSelectJustificar = (attendance) => {
        if(attendance){
            setAttendance(attendance);
        }else{
            setAttendance(null);
        }  
    };

    const handleRequestJustificar = (excuse) => {
        excuse.latitude = latitude;
        excuse.longitude = longitude;
        execute("POST", protectedResources.api.excuses, excuse).then((response) => {
            if (response && response.status == 200) {
                setAttendance(null);
            }
        }).catch((error) => {
            console.log(error);

        });
    }

    if (error) {
        toast.error(error.message, {
            toastId: customId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    const renderForm = (

        <Card variant="outlined">
            <h2 className="tituloH2 mt-3">Registro de inasistencias</h2>
            {!attendance && <MyEmployeesGrid handleSelectJustificar={handleSelectJustificar} />}
            {attendance && <MyEmployeesForm handleSelectJustificar={handleSelectJustificar} attendance={attendance}  handleRequestJustificar={handleRequestJustificar} />}
        </Card>

    );

    return (
        <div className="container"> {isLoading ? <LoadingSpinner /> : renderForm}</div>
    );
}
